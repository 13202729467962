import { template as template_afe8e0927ee240d1a87865c80a4237f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_afe8e0927ee240d1a87865c80a4237f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
