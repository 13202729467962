import { template as template_512764c191d141c1b806336506171b15 } from "@ember/template-compiler";
const FKControlMenuContainer = template_512764c191d141c1b806336506171b15(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
